import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/pd" + "/dsw" + "/jobs"

const useDSWJobsEndpoints = () => {
    const create = generatePostEndpoint<{date: string}, string>(baseURL + "/create"); 
    const stop = generatePostEndpoint<{jobId: string}, string>(baseURL + "/stop");
    const view = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/view");

    return {
        create,
        stop,
        view
    }
}

export {
    baseURL,
    useDSWJobsEndpoints,
};