import { useEffect, Children, useId, cloneElement, useRef, useState, CSSProperties } from "react";
import { RenderSubComponentProps } from "./types"
import { Col } from "../grid";
import { TableTitle } from ".";
import { LuCalendarRange } from "react-icons/lu";
import { DateRangeFetch, DateRangeProps, InfoToolTipGlobal, InfoToolTipProps, PopUp } from "src/components";
import { classCombine } from "@fairview-group/utils";
import { getWidth } from "src/shell";

export const parsePercentString = (percent: string) => {
    if (!percent) return "";
    return Number(percent.replace("%", ""));
}

interface ColumnHeaderToolTipProps extends InfoToolTipProps {
    children: React.ReactNode,
    style?: CSSProperties
}

export const ColumnHeaderToolTip = ({ tooltip, children, style }: ColumnHeaderToolTipProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.25rem",
                alignItems: "center",
                ...style
            }}
        >
            {children}

            <InfoToolTipGlobal
                tooltip={tooltip}
            />
        </div>
    )
}

export const TableSubComponentWrapper = (props: RenderSubComponentProps) => {

    const id = useId();
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    function tableResizeHandler() {
        if (props.row.getIsExpanded()) {
            const elem = document.getElementById(id);
            const tableWrapper = document.getElementById(`${props.id}-table-wrapper-2`);
            elem.style.width = `${tableWrapper.clientWidth}px`;
        }
    }

    function tableScrollHandler() {
        const table = document.getElementById(`${props.id}-table-wrapper`);
        ref2.current.style.transform = `translateX(${table.scrollLeft}px)`;
    }

    useEffect(() => {
        if (props.row.getIsExpanded()) {
            if (!props.removeMaxWidthLimit) {
                tableResizeHandler();
                window.addEventListener("resize", tableResizeHandler);
                if (!props.removeScroll) {
                    tableScrollHandler();
                    const table = document.getElementById(`${props.id}-table-wrapper`);
                    table.addEventListener("scroll", tableScrollHandler);
                }
            }
        }

        // remove listener on unMount
        return () => {
            const table = document.getElementById(`${props.id}-table-wrapper`);
            if (table) table.removeEventListener("scroll", tableScrollHandler);
            window.removeEventListener("resize", tableResizeHandler);
        }
    }, [props.row.getIsExpanded()])

    return (
        props.row.getIsExpanded() &&
        <tr>
            {/* 2nd row is a custom 1 cell row */}
            <td className={"v2"} colSpan={props.row.getVisibleCells().length}>
                <div
                    ref={ref1}
                    id={id}
                    style={{
                        display: 'flex',
                        padding: "0"
                    }}
                >
                    <div
                        ref={ref2}
                        id={`${id}-table-sub-component`}
                        style={{
                            position: "relative",
                            width: "inherit",
                        }}
                    >
                        {
                            props.children
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
}

interface TableTitleDateRangeProps {
    dateRange: DateRangeProps,
    children: React.ReactNode,
    displayState: boolean,
    onDisplayStateChange: (value: boolean) => any
}

export const TableTitleDateRange = (props: TableTitleDateRangeProps) => {

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "0.5rem",
                    flexWrap: "wrap"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        // width: "min-content"
                        alignItems: "center",
                        gap: "1.0rem",
                        fontSize: "1.5rem",
                        lineHeight: "1.5rem",
                        fontWeight: "300",
                        flexWrap: "wrap",
                        userSelect: "none",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none"
                    }}
                >
                    {
                        props.children
                    }
                    <div
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        className={classCombine("title-date-range", (props.dateRange.fromValue || props.dateRange.toValue) ? "active" : "")}
                        onClick={() => {
                            props.onDisplayStateChange(!props.displayState);
                        }}
                    >

                        <LuCalendarRange size={24} />
                    </div>
                </div>
                {
                    props.displayState && <DateRangeFetch
                        {
                        ...props.dateRange
                        }
                        presets={props.dateRange.presets === undefined ? true : props.dateRange.presets}
                    />
                }
            </div>
        </div>
    )
}