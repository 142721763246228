import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useBusinessDiscussionTypeEndpoints } from "./type";
import { useBusinessDiscussionCategoryEndpoints } from "./category";

const baseURL = "/business-discussion"

const useBusinessDiscussionEndpoints = () => {
    const acknowledge = generatePostEndpoint<{businessDiscussionId: string}, string>(baseURL + "/acknowledge");
    const addNote = generatePostEndpoint<{businessDiscussion: any}, any>(baseURL + "/add-note");
    const approve = generatePostEndpoint<{businessDiscussions: any, action: "approve" | "reject"}, any>(baseURL + "/approve");
    const create = generatePostEndpoint<{businessDiscussion: any}, string>(baseURL + "/create");
    const deleteBD = generatePostEndpoint<{businessDiscussions: Array<string>}, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{businessDiscussion: any}, any>(baseURL + "/edit");
    const editOther = generatePostEndpoint<{businessDiscussion: any}, any>(baseURL + "/edit-other");
    const fetch = generatePostEndpoint<{businessDiscussionId: string}, Array<any>>(baseURL + "/fetch");
    const fetchAcknowledged = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-acknowledged");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all");
    const fetchEmployees = generateGetEndpoint<Array<any>>(baseURL + "/fetch-employees");
    const fetchSubmitted = generatePostEndpoint<{from?: Number, to?: number}, Array<any>>(baseURL + "/fetch-submitted");
    const fetchUnacknowledged = generateGetEndpoint<Array<any>>(baseURL + "/fetch-unacknowledged");
    const publish = generatePostEndpoint<{businessDiscussions: any, action: "publish" | "unpublish"}, any>(baseURL + "/publish");

    const category = useBusinessDiscussionCategoryEndpoints();
    const type = useBusinessDiscussionTypeEndpoints();

    return {
        acknowledge,
        addNote,
        approve,
        create,
        deleteBD,
        edit,
        editOther,
        fetch,
        fetchAcknowledged,
        fetchAll,
        fetchEmployees,
        fetchSubmitted,
        fetchUnacknowledged,
        publish,
        category,
        type,
    }
}

export {
    baseURL,
    useBusinessDiscussionEndpoints
};