import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useScheduleGroupEndpoints } from "./group";

const baseURL = "/schedule"

const useScheduleEndpoints = () => {
    const deleteSchedule = generatePostEndpoint<{schedules: Array<string>}, string>(baseURL + "/delete");
    const fetch = generateGetEndpoint<{schedules: Array<any>, roles: Array<any>}>(baseURL + "/fetch");
    const fetchRoles = generateGetEndpoint<Array<any>>(baseURL + "/fetch-roles");
    const upload = generatePostEndpoint<FormData, string>(baseURL + "/upload");

    const group = useScheduleGroupEndpoints();

    return {
        deleteSchedule,
        fetch,
        fetchRoles,
        upload,
        group,
    }
}

export {
    baseURL,
    useScheduleEndpoints
};