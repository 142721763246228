import React, {useState, useContext, useEffect, useMemo, Fragment} from "react";
import { PopUpFilterMapppedProps, PopUpFilterNumberRangeProps, PopUpFilterProps, PopUpProps } from "./types";
import { v4 as uuid } from "uuid";
import { ThemeContext } from "../theme";
import { Input, OptionCustom } from "../input";
import { HiSearch } from "react-icons/hi";
import { FilterFn } from "@tanstack/react-table";
import { getDayOfWeekString, dayStrings, convertDateNumToLocal } from "@fairview-group/utils";
import { Button } from "../buttons";
import { Text } from "../typography";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { DateNumInput } from "src/components";
import { Log } from "src/utils";

export const parseNKeys = ( keyArray: Array<string>, object: any ): any => {
    let value = object?.[keyArray[0]];
    let i = 1;
    while ( i < keyArray.length ) {
        value = value?.[keyArray[i]];
        i++;
    }
    return value;
};

export const baseFilterFn = (a: any, b: any): boolean => {
    return b !== undefined
    ? String( b )
        .toLowerCase()
        .includes( String( a ).toLowerCase() )
    : false;
}

export const originalFilterString: FilterFn<any> = ( row, columnId, value ) => {
    const rowValue = row.getValue(columnId);

    if (Array.isArray(value)) {
        if (value.length === 0) return true;
        let compareCheck = false;
        value.forEach((subValue) => {
            compareCheck = compareCheck || String( rowValue ).toLowerCase() === ( String( subValue ).toLowerCase() );
        })
        return compareCheck;
    } else {
        return baseFilterFn(rowValue, value);
    }
};

export const filterDayOfWeek: FilterFn<any> = ( row, columnId, value ) => {
    const rowValue = getDayOfWeekString( new Date( row.original[columnId] ).getDay() );
    return rowValue !== undefined
        ? String( rowValue )
            .toLowerCase()
            .startsWith( String( value ).toLowerCase() )
        : false;
};

export const filterBoolean: FilterFn<any> = ( row, columnId, value ) => {
    const rowValue = row.original[columnId] ;
    return rowValue !== undefined
        ? rowValue
        : false;
};

export const originalFilterDate: FilterFn<any> = ( row, columnId, value ) => {
    const keys = columnId.split( "." );
    let tempValue = parseNKeys( keys, row.original );
    if (!tempValue) return false;
    let tempUTCDate = new Date(tempValue);
    let tempLocalDate = new Date( tempUTCDate.getTime() - ( tempUTCDate.getTimezoneOffset() * 60000 ) ).toISOString().split( "T" );
    const rowValue = tempLocalDate[0];
    return rowValue !== undefined
        ? String( rowValue )
            .toLowerCase()
            .startsWith( String( value ).toLowerCase() )
        : false;
};

export const originalFilterJSX: FilterFn<any> = ( row, columnId, value ) => {
    const rowValue = row.original[columnId].props.id;
    return rowValue !== undefined
        ? String( rowValue )
            .toLowerCase()
            .startsWith( String( value ).toLowerCase() )
        : false;
};
export const filterDateRange: FilterFn<any> = ( row, columnId, value ) => {
    
    const rowValue = Number(parseNKeys(columnId.split("."), row.original));
    // console.log(value, rowValue);
    const valueArray = Array.isArray(value) ? value : [null, null];
    const from = new Date(Number(valueArray[0])).setHours(0, 0, 0, 0);
    const to = new Date(Number(valueArray[1])).setHours(23, 59, 59, 999);

    return (from <= rowValue) && (rowValue <= to);
};

export const filterNumberRange: FilterFn<any> = ( row, columnId, value ) => {
    
    // const rowValue = Number(parseNKeys(columnId.split("."), row.original));
    // console.log(value, rowValue, row.getValue(columnId));
    const rowValue = Number(row.getValue(columnId));
    const valueArray = Array.isArray(value) ? value : [null, null];

    return (Number(valueArray[0]) <= rowValue) && (rowValue <= Number(valueArray[1]));
};


export const baseFilterFnMapped = (a: any, b: any, map: {[key: string]: string}): boolean => {
    return b !== undefined
    ? String( map[b] ?? b )
        .toLowerCase()
        .includes( String( a ).toLowerCase() )
    : false;
}


const PopUp = (props: PopUpProps) => {
    
    const themeContext = useContext(ThemeContext);
    const [popUpId, setPopUpId] = useState<string>(uuid())

    useEffect(() => {
        if (props.popUpState) {
            const widthFilter = 250;
            const height = 300;
            const tableWrapper = document.getElementById(props.tableWrapperId)
            if (tableWrapper) {
                const tableBounds = tableWrapper.getBoundingClientRect();
                const header = document.getElementById(`${props.column.id}`)
                const headerBounds = header.getBoundingClientRect();
                const filterButton = document.getElementById(`${props.column.id}-filter-sort`)
                const filterButtonBounds = filterButton.getBoundingClientRect();
                const popUp = document.getElementById(popUpId);
                // console.log(tableBounds, headerBounds, filterButtonBounds);
    
                const elem = document.getElementById( "content" );
                // console.log(width);
                const isFullscreen = elem.classList.contains("fullscreen");
    
    
                const padding = 32;
                const offset = (!isFullscreen ? 271 : 0) + padding;
                const tablePadding = 8;
                // let left = headerBounds.x + headerBounds.width - offset - filterButtonBounds.width;
                let left = filterButtonBounds.x - tableBounds.x
                // console.log(exportBounds.height, tableExports);
                // let top = headerBounds.height + tablePadding 
                // + (tableExports ? exportBounds.height + 32 : 0);
                let top = headerBounds.y - tableBounds.y + headerBounds.height;
                // console.log(left, window.innerWidth, tableBounds.width);
    
                if ((left + widthFilter) > tableBounds.width) {
                    // left = headerBounds.x + headerBounds.width - offset - widthFilter + tablePadding;
                    left = filterButtonBounds.x - tableBounds.x - widthFilter + filterButtonBounds.width;
                }
    
                if (((left - widthFilter) < 0) && (window.innerWidth < (400 + offset))) {
                    left = tableBounds.width / 2 - widthFilter / 2;
                    // left = tableBounds.width / 2;
                }
    
                popUp.style.top = `${top}px`;
                popUp.style.left = `${left}px`
                popUp.style.scale = `1 1`;
            }
            
        }
    }, [props.popUpState])

    
    const handleOutsideClick = (event) => {
        if (document.getElementById(popUpId)) {
            if (!document.getElementById(popUpId).contains(event.target)) {
                props.onPopUpClose(false);
            }
        }
    }

    useEffect(()=> {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, []);

    return (
        
        props.popUpState && <div 
            id={popUpId}
            className={`pop-up-filter-parent ${themeContext.value}`}
            style={{
                width: "250px", 
                // height: "300px"
            }}
        >
           {props.search.enable && <Input
                placeholder="Search..."
                icon={<HiSearch size={16}/>}
                width={"100%"}
                inputSize={"sm"}
                value={props.search?.value}
                onChange={(e) => props.search?.onChange(e.currentTarget.value)}
            />}
            <div className={`pop-up-filter-options ${themeContext.value}`}>
                { props.children }
            </div>
            <div className={"pop-up-filter-btn-container"}>
                <Button size={"sm"} buttonType={"primary"} onClick={props.onApply}>Apply</Button>
                <Button size={"sm"} variant={"outlined"} onClick={props.onReset}>Reset</Button>
            </div>
        </div>
    )
}

export const PopUpFilter = (props: PopUpFilterProps) => {
    const [filterMap, setFilterMap] = useState<any>({});
    const [search, setSearch] = useState<string>("");

    
    const sortedUniqueValues = useMemo(
        () => Array.from(props.column.getFacetedUniqueValues().keys()).sort(),
        [props.column.getFacetedUniqueValues()]
    )

    useEffect(() => {
        const initialState = props.column.getFilterValue() as Array<any>;
        if (initialState && props.popUpState) {
            setFilterMap(
                initialState.reduce((acc, curr) => {
                    return {...acc, [curr]: true}
                }, {})  
            )
        }
    }, [props.column.getFilterValue(), props.popUpState])

    return (
        <PopUp
            search={{
                enable: true,
                value: search,
                onChange: (value) => {setSearch(value)}
            }}
            {...props}
            onApply={() => {
                const values = Object.keys(filterMap)
                props.column.setFilterValue(values); 
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    // url.searchParams.set(props.column.id, values.toString())
                    url.searchParams.delete(props.column.id);
                    values.forEach((value) => url.searchParams.append(props.column.id, value));
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
            onReset={() => {
                setFilterMap({}); 
                props.column.setFilterValue("");
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id)
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
        >
            <OptionCustom
                className={"pop-up-filter-option"}
                size={"small"}
                onClick={()=> {
                    if (!filterMap["select-all"]) {
                        filterMap["select-all"] = true;
                        sortedUniqueValues.forEach((value) => {
                            filterMap[value] = true;
                        })
                        setFilterMap({...filterMap})
                    } else {
                        setFilterMap({});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap["select-all"] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            filterMap["select-all"] = true;
                            sortedUniqueValues.forEach((value) => {
                                filterMap[value] = true;
                            })
                            setFilterMap({...filterMap})
                        } else {
                            setFilterMap({});
                        }
                    }}
                />Select All
            </OptionCustom>
            {typeof sortedUniqueValues[0] !== "object" && sortedUniqueValues.map((value) => {
                return (
                    (value && baseFilterFn(search, value)) 
                    ? <OptionCustom 
                        className={"pop-up-filter-option"}
                        value={value} 
                        key={value} 
                        size={"small"}
                        onClick={()=> {
                            if (!filterMap[value]) {
                                filterMap[value] = true;
                                setFilterMap({...filterMap})
                            } else {
                                delete filterMap[value];
                                setFilterMap({...filterMap});
                            }
                        }}
                    >
                        <input 
                            className={"pop-up-filter-check"}
                            type={"checkbox"} 
                            checked={filterMap[value] ? true : false} 
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    setFilterMap({...filterMap, [`${value}`]: e.currentTarget.checked})
                                } else {
                                    delete filterMap[value];
                                    setFilterMap({...filterMap});
                                }
                            }}
                        />{value}
                    </OptionCustom> 
                    : null
                )
            })}
        </PopUp>
                
    )
}

export const PopUpFilterMapped = (props: PopUpFilterMapppedProps) => {
    const [filterMap, setFilterMap] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    
    const sortedUniqueValues: [Array<string>, {[key:string]: Array<string>}] = useMemo(
        () => {
            const uniqueMap = Array.from(props.column.getFacetedUniqueValues().keys()).reduce((acc, value) => {
                // console.log(props.map[value] ?? value, acc[props.map[value] ?? value] ?? []);
                if (props.map[value] ?? value) {
                    const newArray = !acc[props.map[value] ?? value] ? [value] : [...acc[props.map[value] ?? value], value];
                    return {...acc, [props.map[value] ?? value]: newArray}
                } else {
                    return {...acc};
                }
            }, {});

            // console.log(uniqueMap, Object.keys(uniqueMap));
            return [Object.keys(uniqueMap).sort(), uniqueMap]

        },
        [props.column.getFacetedUniqueValues(), props.map]
    )

    useEffect(() => {
        const initialState = props.column.getFilterValue() as Array<any>;
        if (initialState && props.popUpState) {
            setFilterMap(
                initialState.reduce((acc, curr) => {
                    return {...acc, [curr]: true}
                }, {})  
            )
        }
    }, [props.column.getFilterValue(), props.popUpState])

    useEffect(() => {
        Log(filterMap)
    }, [filterMap])

    return (
        <PopUp
            search={{
                enable: true,
                value: search,
                onChange: (value) => {setSearch(value)}
            }}
            {...props}
            onApply={() => {
                const values = Object.keys(filterMap)
                props.column.setFilterValue(values); 
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    // url.searchParams.set(props.column.id, values.toString())
                    url.searchParams.delete(props.column.id);
                    values.forEach((value) => url.searchParams.append(props.column.id, value));
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
            onReset={() => {
                setFilterMap({}); 
                props.column.setFilterValue("");
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id)
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
        >
            <OptionCustom
                className={"pop-up-filter-option"}
                size={"small"}
                onClick={()=> {
                    if (!filterMap["select-all"]) {
                        filterMap["select-all"] = true;
                        sortedUniqueValues[0].forEach((value) => {
                            // filterMap[value] = true;
                            sortedUniqueValues[1][value].forEach((value) => {
                                filterMap[value] = true;
                            })
                        })
                        setFilterMap({...filterMap})
                    } else {
                        setFilterMap({});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap["select-all"] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            filterMap["select-all"] = true;
                            sortedUniqueValues[0].forEach((value) => {
                                // filterMap[value] = true;
                                sortedUniqueValues[1][value].forEach((value) => {
                                    filterMap[value] = true;
                                })
                            })
                            setFilterMap({...filterMap})
                        } else {
                            setFilterMap({});
                        }
                    }}
                />Select All
            </OptionCustom>
            {typeof sortedUniqueValues[0][0] !== "object" && sortedUniqueValues[0].map((value) => {
                return (
                    (value && baseFilterFnMapped(search, value, props.map)) 
                    ? <OptionCustom 
                        className={"pop-up-filter-option"}
                        value={value} 
                        key={value} 
                        size={"small"}
                        onClick={()=> {
                            if (!filterMap[value]) {
                                sortedUniqueValues[1][value].forEach((value) => {
                                    filterMap[value] = true;
                                })
                                setFilterMap({...filterMap})
                            } else {
                                delete filterMap[value];
                                setFilterMap({...filterMap});
                            }
                        }}
                    >
                        <input 
                            className={"pop-up-filter-check"}
                            type={"checkbox"} 
                            // checked={filterMap[value] ? true : false} 
                            checked={sortedUniqueValues[1][value].reduce((acc, value) => {
                                return (filterMap[value] ? true : false) || acc;
                            }, false)}
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    sortedUniqueValues[1][value].forEach((value) => {
                                        filterMap[value] = e.currentTarget.checked;
                                    })
                                    // setFilterMap({...filterMap, [`${value}`]: e.currentTarget.checked})
                                } else {
                                    (sortedUniqueValues[1]?.[value] ?? []).forEach((key) => {
                                        delete filterMap[key];
                                    })
                                    // delete filterMap[value];
                                    setFilterMap({...filterMap});
                                }
                            }}
                        />{props.map[value] ?? value}
                    </OptionCustom> 
                    : null
                )
            })}
        </PopUp>
                
    )
}

export const PopUpFilterNumberRange = (props: PopUpFilterNumberRangeProps) => {
    const themeContext = useContext(ThemeContext);

    const [min, max] = useMemo(() => {
        const sortedArray = Array.from(props.column.getFacetedUniqueValues().keys()).sort((a ,b) => {
            if (a > b) return 1;
            if ( a < b) return -1;
            return 0;
        });
        let max = sortedArray[0];

        for (let i = sortedArray.length - 1; i > 0; i--) {
            if (sortedArray[i] !== undefined) {
                max = sortedArray[i];
                break;
            }
        }
        // console.log(sortedArray[0], max);
        return [sortedArray[0], max]
    }, [props.column.getFacetedUniqueValues()])

    
    // console.log(props.column.id, min, max);
    const [inputRange, setInputRange] = useState<Array<number>>([min, max]);

    useEffect( () => {
        if (props.column.getFilterValue() && props.column.getFilterValue().toString() !== inputRange.toString()) {
            // setRangeNum(props.column.getFilterValue());
            const value = props.column.getFilterValue();
            setInputRange(Array.isArray(value) ? value : [min, max]);
        }
    }, [props.column.getFilterValue()])

    const divProps = Object.assign({}, props);
    delete divProps.step;

    return (
        <PopUp
            search={{
                enable: false,
            }}
            {...divProps}
            onApply={() => {
                // props.column.setFilterValue(rangeNum);
                props.column.setFilterValue(inputRange);
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id);
                    inputRange.forEach((value, i) => url.searchParams.append(props.column.id, value.toString()))
                    // url.searchParams.set(props.column.id, rangeNum.toString())
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
            onReset={() => {
                // setRangeNum(max);
                setInputRange([min, max]);
                props.column.setFilterValue("");
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id)
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto auto",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                    columnGap: "4px"
                }}
            >
                <Input
                    inputSize={"sm"}
                    width={"75px"}
                    value={inputRange[0] ?? ""}
                    type={"number"}
                    onChange={(e) => {
                        inputRange[0] = e.currentTarget.value as any;
                        setInputRange([...inputRange]);
                    }}
                />
                <div>
                    {"\u2264"}
                </div>
                {"value"}
                <div>
                    {"\u2264"}
                </div>
                <Input
                    inputSize={"sm"}
                    width={"75px"}
                    value={inputRange[1] ?? ""}
                    type={"number"}
                    onChange={(e) => {
                        inputRange[1] = e.currentTarget.value as any;
                        setInputRange([...inputRange]);
                    }}
                />
            </div>
        </PopUp>
                
    )
}

export const PopUpFilterDOW = (props: PopUpFilterProps) => {
    const [filterMap, setFilterMap] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    
    const sortedUniqueValues = useMemo(
        () => Array.from(props.column.getFacetedUniqueValues().keys()).sort(),
        [props.column.getFacetedUniqueValues()]
    )

    useEffect(() => {
        const initialState = props.column.getFilterValue() as Array<any>;
        if (initialState && props.popUpState) {
            setFilterMap(
                initialState.reduce((acc, curr) => {
                    return {...acc, [curr]: true}
                }, {})  
            )
        }
    }, [props.column.getFilterValue(), props.popUpState])

    return (
        <PopUp
            search={{
                enable: true,
                value: search,
                onChange: (value) => {setSearch(value)}
            }}
            {...props}
            onApply={() => {
                const values = Object.keys(filterMap)
                props.column.setFilterValue(values); 
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    // url.searchParams.set(props.column.id, values.toString())
                    url.searchParams.delete(props.column.id);
                    values.forEach((value) => url.searchParams.append(props.column.id, value));
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
            onReset={() => {
                setFilterMap({}); 
                props.column.setFilterValue("");
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id)
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
        >
            <OptionCustom
                className={"pop-up-filter-option"}
                size={"small"}
                onClick={()=> {
                    if (!filterMap["select-all"]) {
                        filterMap["select-all"] = true;
                        sortedUniqueValues.forEach((value) => {
                            filterMap[value] = true;
                        })
                        setFilterMap({...filterMap})
                    } else {
                        setFilterMap({});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap["select-all"] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            filterMap["select-all"] = true;
                            sortedUniqueValues.forEach((value) => {
                                filterMap[value] = true;
                            })
                            setFilterMap({...filterMap})
                        } else {
                            setFilterMap({});
                        }
                    }}
                />Select All
            </OptionCustom>
            {dayStrings.map((value) => {
                return (
                    (value && baseFilterFn(search, value)) 
                    ? <OptionCustom 
                        className={"pop-up-filter-option"}
                        value={value} 
                        key={value} 
                        size={"small"}
                        onClick={()=> {
                            if (!filterMap[value]) {
                                filterMap[value] = true;
                                setFilterMap({...filterMap})
                            } else {
                                delete filterMap[value];
                                setFilterMap({...filterMap});
                            }
                        }}
                    >
                        <input 
                            className={"pop-up-filter-check"}
                            type={"checkbox"} 
                            checked={filterMap[value] ? true : false} 
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    setFilterMap({...filterMap, [`${value}`]: e.currentTarget.checked})
                                } else {
                                    delete filterMap[value];
                                    setFilterMap({...filterMap});
                                }
                            }}
                        />{value}
                    </OptionCustom> 
                    : null
                )
            })}
        </PopUp>
                
    )
}

// interface uniqueDate {
//     [key: string]: {
//         [key: string]: string | boolean
//     }
// }


export const PopUpFilterDate = (props: PopUpFilterProps) => {
    
    const sortedUniqueValues = useMemo(
        () => Array.from(props.column.getFacetedUniqueValues().keys()).sort(),
        [props.column.getFacetedUniqueValues()]
    )

    const [min, max] = useMemo(() => {
        const sortedArray = Array.from(props.column.getFacetedUniqueValues().keys()).sort((a ,b) => {
            if (a > b) return 1;
            if ( a < b) return -1;
            return 0;
        });
        let max = sortedArray[0];

        for (let i = sortedArray.length - 1; i > 0; i--) {
            if (sortedArray[i] !== undefined) {
                max = sortedArray[i];
                break;
            }
        }
        return [sortedArray[0], max]
    }, [props.column.getFacetedUniqueValues()])
        
    const [from, setFrom] = useState<number>(min);
    const [to, setTo] = useState<number>(max);

    useEffect( () => {
        if (props.column.getFilterValue() && props.column.getFilterValue().toString() !== [from, to].toString()) {
            const value = props.column.getFilterValue();
            setFrom(Number(value[0]));
            setTo(Number(value[1]));
        }
    }, [props.column.getFilterValue()])

    return (
        <PopUp
            search={{
                enable: false,
            }}
            {...props}
            onApply={() => {
                props.column.setFilterValue([from || min, to || max]);
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id);
                    [from || min, to || max].forEach(value => url.searchParams.append(props.column.id, value.toString()))
                    // url.searchParams.set(props.column.id, rangeNum.toString())
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
            onReset={() => {
                setFrom(min);
                setTo(max);
                props.column.setFilterValue("");
                if (props.enableURLParams) {
                    const url = new URL(window.location.href);
                    url.searchParams.delete(props.column.id)
                    window.history.pushState({}, "", url);
                }
                props.table.setPageIndex(0);
            }}
        >
            <DateNumInput
                label={"From"}
                width={"100%"}
                value={from}
                type={"date"}
                inputSize={"sm"}
                onChange={(date) => {
                    setFrom(date);
                }}
            />
            
            <DateNumInput
                label={"To"}
                width={"100%"}
                value={to}
                type={"date"}
                inputSize={"sm"}
                onChange={(date) => {
                    setTo(date);
                }}
            />
        </PopUp>
                
    )
}

interface PopUpFilterBooleanProps extends PopUpFilterProps {
    trueDisplayValue?: any
    falseDisplayValue?: any
    undefinedDisplayValue?: any
}

export const PopUpFilterBoolean = (props: PopUpFilterBooleanProps) => {
    const [filterMap, setFilterMap] = useState<any>({});
    const [search, setSearch] = useState<string>("");

    
    const sortedUniqueValues = useMemo(
        () => Array.from(props.column.getFacetedUniqueValues().keys()).sort(),
        [props.column.getFacetedUniqueValues()]
    )

    return (
        <PopUp
            search={{
                enable: true,
                value: search,
                onChange: (value) => {setSearch(value)}
            }}
            {...props}
            onApply={() => {props.column.setFilterValue(Object.keys(filterMap))}}
            onReset={() => {setFilterMap({}); props.column.setFilterValue("")}}
        >
            <OptionCustom
                className={"pop-up-filter-option"}
                size={"small"}
                onClick={()=> {
                    if (!filterMap["select-all"]) {
                        filterMap["select-all"] = true;
                        sortedUniqueValues.forEach((value) => {
                            filterMap[value] = true;
                        })
                        setFilterMap({...filterMap})
                    } else {
                        setFilterMap({});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap["select-all"] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            filterMap["select-all"] = true;
                            sortedUniqueValues.forEach((value) => {
                                filterMap[value] = true;
                            })
                            setFilterMap({...filterMap})
                        } else {
                            setFilterMap({});
                        }
                    }}
                />Select All
            </OptionCustom>

            { props.trueDisplayValue && baseFilterFn(search, props.trueDisplayValue) &&< OptionCustom 
                className={"pop-up-filter-option"}
                value={true} 
                size={"small"}
                onClick={()=> {
                    if (!filterMap[`true`]) {
                        filterMap[`true`] = true;
                        setFilterMap({...filterMap})
                    } else {
                        delete filterMap[`true`];
                        setFilterMap({...filterMap});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap[`true`] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setFilterMap({...filterMap, [`true`]: e.currentTarget.checked})
                        } else {
                            delete filterMap[`true`];
                            setFilterMap({...filterMap});
                        }
                    }}
                />{props.trueDisplayValue}
            </OptionCustom> }

            { props.falseDisplayValue && baseFilterFn(search, props.falseDisplayValue) &&< OptionCustom 
                className={"pop-up-filter-option"}
                value={false} 
                size={"small"}
                onClick={()=> {
                    if (!filterMap[`false`]) {
                        filterMap[`false`] = true;
                        setFilterMap({...filterMap})
                    } else {
                        delete filterMap[`false`];
                        setFilterMap({...filterMap});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap[`false`] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setFilterMap({...filterMap, [`false`]: e.currentTarget.checked})
                        } else {
                            delete filterMap[`false`];
                            setFilterMap({...filterMap});
                        }
                    }}
                />{props.falseDisplayValue}
            </OptionCustom> }

            { props.undefinedDisplayValue && baseFilterFn(search, props.undefinedDisplayValue) &&< OptionCustom 
                className={"pop-up-filter-option"}
                value={undefined} 
                size={"small"}
                onClick={()=> {
                    if (!filterMap[`undefined`]) {
                        filterMap[`undefined`] = true;
                        setFilterMap({...filterMap})
                    } else {
                        delete filterMap[`undefined`];
                        setFilterMap({...filterMap});
                    }
                }}
            >
                <input 
                    className={"pop-up-filter-check"}
                    type={"checkbox"} 
                    checked={filterMap[`undefined`] ? true : false} 
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setFilterMap({...filterMap, [`undefined`]: e.currentTarget.checked})
                        } else {
                            delete filterMap[`undefined`];
                            setFilterMap({...filterMap});
                        }
                    }}
                />{props.undefinedDisplayValue}
            </OptionCustom> }
        </PopUp>
                
    )
}