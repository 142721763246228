import PopUp, { PopUpPropType } from "..";

interface DeletePopUpProps extends Omit<PopUpPropType, "options"> {
    onDelete: () => void,
    loadState: boolean,
}

export const DeletePopUp = (props: DeletePopUpProps) => {

    return (
        <PopUp
        popUpState={props.popUpState}
        onPopUpClose={props.onPopUpClose}
            title={"Warning"}
            options={{
                justifyContent: "end",
                left: {
                    size: "sm",
                    children: "Delete",
                    onClick: () => {
                        props.onDelete()
                    },
                    loadState: props.loadState,
                    background: "#F02435",
                    color: "#FFFFFF"
                },
                right: {
                    children: "Cancel",
                    onClick: () => {
                        props.onPopUpClose(false);
                        // setPopUp(false)
                    },
                    variant: "outlined",
                    size: "sm",
                    disabled: props.loadState
                }
            }}
        >
            This action is permanent. Are you sure?
        </PopUp>
    )
}