import React, { Fragment, useContext, useEffect, useRef, useState, FC, CSSProperties, HTMLProps } from "react";

import * as DivUtils from "../@utils";
import { ThemeContext } from "../theme";
import { v4 as uuid } from "uuid";

import {
    Column,
    Table,
    ColumnDef,
    ColumnFiltersState,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    FilterFn,
    SortingFn,
    sortingFns,
    getExpandedRowModel,
    ExpandedState,
    PaginationState,
    Row,
    ColumnFilter,
    FilterFnOption,
    createColumnHelper,
    RowData,
    Header
} from "@tanstack/react-table";
import { makeData, Person } from "./makeData";
import { customFilterProps, ExportOption, PopUpFilterProps, TableProps } from "./types";

import {
    RankingInfo,
    rankItem,
    compareItems
} from "@tanstack/match-sorter-utils";
import { Input, Option, OptionCustom, SearchSelectCustom, Select, SelectCustom } from "../input";
import { Button } from "../buttons";
import { FaFilter, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { HiSearch } from "react-icons/hi";
import xlsx from "xlsx";
import { BiExport, BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { filterBoolean, filterDateRange, filterDayOfWeek, filterNumberRange, originalFilterDate, originalFilterJSX, originalFilterString, PopUpFilter, PopUpFilterDate, PopUpFilterNumberRange } from "./filters";
import { useDrag, useDrop } from 'react-dnd'
import { MdDragHandle, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { Log } from "src/utils";
import { classCombine, convertDateNumToLocal } from "@fairview-group/utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TableActionPopUp } from "./actions";
import { Text, Title } from "../typography";
import { IoOptions, IoOptionsOutline } from "react-icons/io5";
import { LuCalendarRange } from "react-icons/lu";
import Skeleton from "../skeleton";
import { HiMiniChevronDoubleLeft, HiMiniChevronDoubleRight, HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import { DateRangeFetch, PopUp } from "src/components";
import { TableTitleDateRange, } from "./utils";
import { getWidth } from "src/shell";
import { TbLayoutSidebarLeftExpand, TbLayoutSidebarRightExpand } from "react-icons/tb";

declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>,
        originalString: FilterFn<unknown>,
        originalDate: FilterFn<unknown>,
        originalJSX: FilterFn<unknown>,
        filterDayOfWeek: FilterFn<unknown>,
        filterBoolean: FilterFn<unknown>,
        filterNumberRange: FilterFn<unknown>,
        filterDateRange: FilterFn<unknown>,
    }

    interface FilterMeta {
        itemRank: RankingInfo;
    }

    interface SortingFns {
        JSXsortByID: SortingFn<unknown>;
    }
}

declare module '@tanstack/react-table' {

    interface TableMeta<TData extends RowData> {
        updateData: (rowIndex: number, columnId: string, value: unknown) => void
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};



const JSXsortByID: SortingFn<any> = (rowA, rowB, columnId) => {
    const a = rowA.original.icon.props.id;
    const b = rowB.original.icon.props.id;
    return a > b ? 1 : a === b ? 0 : -1;
};

export const BaseFilter = (props: { value: any, placeholder: string, onChange: Function, children: any }) => {

    return (

        <SearchSelectCustom
            placeholder={props.placeholder}
            className={"base-filter"}
            size={"small"}
            style={{ maxWidth: "300px" }}
            icon={<HiSearch size={16} />}
            value={props.value ?? ""}
            onChange={(display, value) => props.onChange(value)}
        >
            {props.children}
        </SearchSelectCustom>
    )
}


const DraggableRow: FC<{
    row: Row<any>
    reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void,
    active: boolean,
    theme: string,
}> = ({ row, reorderRow, active, theme }) => {
    const [, dropRef] = useDrop({
        accept: 'row',
        drop: (draggedRow: Row<Person>) => reorderRow(draggedRow.index, row.index),
    })

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    })

    return (
        <tr
            ref={previewRef} //previewRef could go here
            style={{ opacity: isDragging ? 0.5 : 1 }}
            className={`table-data ${active ? "active" : ""}`}
        >
            <td ref={dropRef} className={`v2 ${theme}`} style={{ textAlign: "center" }}>
                <div ref={dragRef} style={{ background: "transparent", padding: "1rem", border: "none", position: "relative", cursor: "grab" }}>
                    <MdDragHandle
                        style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            // background: "red"
                        }}
                        size={16}
                    /></div>
            </td>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={`v2 ${theme}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

const getUniqueJSX = (column: Column<any, unknown>) => {
    const uniqueIDs = [];
    const uniqueMap = {};
    column.getFacetedUniqueValues().forEach((value, jsx) => {
        uniqueMap[jsx.props.id] = 1;
    });
    return Object.keys(uniqueMap);
};

const parseTableSearchParams = (): { sorting: SortingState, filterState: ColumnFiltersState, pageIndex: number, pageSize: number } => {
    const searchParams = new URLSearchParams(window.location.href)
    // console.log("search params")
    let paramObj = {};
    let filterState = [];
    let sorting = [];
    let pageIndex = 0;
    let pageSize = 10;
    // console.log('window href', window.location.href)
    for (var value of searchParams.keys()) {
        const val = value.split("?");
        const key = val.length > 1 ? val[1] : val[0]
        paramObj[key] = searchParams.get(value);

        if (filterState.find(state => state.id === key)) continue;

        if (key !== "sortDir" && key !== "sortID" && key !== "page" && key !== "pageSize" && key !== window.location.href) {
            filterState.push({ id: key, value: searchParams.getAll(value) });
        }
    }
    // console.log('filter state temp', filterState);
    if (paramObj["sortDir"] && paramObj["sortID"]) {
        sorting = [{ id: paramObj["sortID"], desc: paramObj["sortDir"] === "desc" }]
    }
    pageIndex = Number(paramObj["page"] ?? 0);
    pageSize = Number(paramObj["pageSize"] ?? 50);
    // console.log('paramObj', paramObj)

    return {
        sorting: sorting,
        filterState: filterState,
        pageIndex: pageIndex,
        pageSize: pageSize
    }
}

export const TableTitle = (props) => {
    const { dense, ...restProps } = props;

    return (
        <Title
            className={classCombine("table-title", dense ? "dense" : "")}
            padding={0}
            {
            ...restProps
            }
            width={"unset"}
        >
            {props.children}
        </Title>
    )
}

export const getDurationFromDate = (date: number) => {
    const time = convertDateNumToLocal(date).split("T")[1];
    const HMS = time?.split(":");
    if (HMS === undefined) return "";
    const hoursSeconds = Number(HMS[0]) * 60 * 60;
    const minutesSeconds = Number(HMS[1]) * 60;
    const seconds = Number(HMS[2]);
    const duration = (hoursSeconds + minutesSeconds + seconds) / (24 * 60 * 60);
    if (Number.isNaN(duration)) return "";
    return duration;
}

export const getDurationFromHHMM = (hhmm: string) => {
    const HMS = hhmm?.split(":");
    if (hhmm === undefined) return "";
    const hoursSeconds = Number(HMS[0]) * 60 * 60;
    const minutesSeconds = Number(HMS[1]) * 60;
    const duration = (hoursSeconds + minutesSeconds + 0) / (24 * 60 * 60);
    if (Number.isNaN(duration)) return "";
    return duration;
}
const getCommonPinningStylesLeft = (column: Column<any>): CSSProperties => {

    return {
        left: `${column.getAfter('left')}px`,
        width: "16px",
    }
}

const getCommonPinningStylesRight = (column: Column<any>): CSSProperties => {

    return {
        right: `${column.getAfter('right')}px`,
        width: "16px",
    }
}

const getCommonPinningStylesGeneric = (column: Column<any>): CSSProperties => {

    return {
        top: `0px`,
        // width: "16px",
    }
}


function useSkipper() {
    const shouldSkipRef = React.useRef(true)
    const shouldSkip = shouldSkipRef.current

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
        shouldSkipRef.current = false
    }, [])

    React.useEffect(() => {
        shouldSkipRef.current = true
    })

    return [shouldSkip, skip] as const
}

const defaultColumn: Partial<ColumnDef<any>> = {
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const initialValue = getValue()
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue)

        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
            table.options.meta?.updateData(index, id, value)
        }

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
            setValue(initialValue)
        }, [initialValue])

        return (
            <Input
                value={value as string ?? ""}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                style={{
                    minWidth: "150px",
                    margin: 0
                }}
            />
        )
    },
}

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <Input
            type="checkbox"
            inputRef={ref}
            className={className}
            inputSize="sm"
            {...rest}
        />
    )
}



const isColumnGroupHidden = (column: Column<any, any>, columnVisibility: { [key: string]: boolean }) => {
    // const depth = header.column.depth;
    const parentColumns = column.parent?.columns ?? [];
    if (columnVisibility[column.id] === false) {
        return true;
    }
    for (let i = 0; i < parentColumns.length; i++) {
        if (columnVisibility[parentColumns[i].id] === false) {
            return true;
        }
    }
    return false;
}

export const TableComponent = (props: TableProps) => {

    const themeContext = useContext(ThemeContext);

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [enablePagination, setEnablePagination] = React.useState<boolean>(props.enablePagination === undefined ? true : props.enablePagination);
    const [previousPageSize, setPreviousPageSize] = React.useState<number>(null);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    );
    const [globalFilter, setGlobalFilter] = React.useState("");
    const tableRef = useRef(null);

    const scrollToTop = () => {
        // smooth does not work when going back?
        tableRef.current.scrollIntoView(
            {
                // behavior: "smooth",
            }
        )
        // window.scrollTo(0, tableRef.current.offsetTop)   
        // document.getElementById("content").scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // })
    }
    const getRowCanExpand = props.getRowCanExpand;

    const [rowSelection, setRowSelection] = React.useState({})

    useEffect(() => {
        // Log(rowSelection, table.getFilteredSelectedRowModel(), table.getGroupedSelectedRowModel(), table.getRowModel())
        if (props.onSelectionChange) {
            props.onSelectionChange(table.getFilteredSelectedRowModel())
        }
    }, [rowSelection])

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({} as PaginationState)
    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const columnHelperActions = createColumnHelper<any>();

    const columnSelect = React.useMemo(() => {
        return [
            {
                id: 'table-select',
                header: ({ table }) => (
                    <div
                        onClick={table.getToggleAllRowsSelectedHandler()}
                        style={{
                            cursor: "pointer"
                        }}
                    >

                        <IndeterminateCheckbox
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />
                    </div>
                ),
                cell: ({ row }) => (
                    <div
                        onClick={row.getToggleSelectedHandler()}
                        style={{
                            cursor: "pointer"
                        }}
                    >
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            },
        ]
    }, [props.data])

    const columnAction = React.useMemo(() => {
        return [
            columnHelperActions.display({
                id: 'table-actions',
                cell: cell =>
                    <div
                    >
                    </div>,
                header: () =>
                    <span
                    >
                    </span>,
            }),
        ]
    }, [props.data])

    const tableData = React.useMemo(() => {
        return props.loadState ? Array(props.expectedRows ?? pageSize ?? 50).fill({}) : props.data
    }, [props.loadState, props.data]);

    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
    const columnsMemo = React.useMemo(() => {
        const columnsData = props.loadState
            ? props.columns.map((column) => {
                return ({
                    ...column,
                    cell: () => <div><Skeleton count={1} width={"100%"} /></div>,
                    // @ts-ignore
                    columns: column.columns?.map((subColumn) => {
                        return ({
                            ...subColumn,
                            cell: () => <div><Skeleton count={1} width={"100%"} /></div>,
                        })
                    })
                })
            })
            : props.columns
        let columns = columnsData;
        if (props.actions?.row) columns = [...columns, ...columnAction];
        if (props.enableRowSelectionColumn) columns = [...columnSelect, ...columns]
        return columns
        // return columnsData;
    }, [props.loadState, props.columns,]);

    const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({});

    const table = useReactTable({
        data: tableData,
        defaultColumn: defaultColumn,
        columns: columnsMemo,
        filterFns: {
            fuzzy: fuzzyFilter,
            originalString: originalFilterString,
            originalDate: originalFilterDate,
            originalJSX: originalFilterJSX,
            filterDayOfWeek: filterDayOfWeek,
            filterBoolean: filterBoolean,
            filterNumberRange: filterNumberRange,
            filterDateRange: filterDateRange,
        },
        sortingFns: {
            JSXsortByID: JSXsortByID
        },
        state: {
            sorting,
            columnFilters,
            globalFilter,
            pagination,
            rowSelection,
            columnVisibility,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getSubRows: props.getSubRow,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowCanExpand: getRowCanExpand,
        getExpandedRowModel: getExpandedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        onColumnVisibilityChange: setColumnVisibility,
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: false,
        enableFilters: true,
        autoResetPageIndex: false,
        manualPagination: true,

        meta: {
            updateData: (rowIndex, columnId, value) => {
                // Skip page index reset until after next rerender
                skipAutoResetPageIndex()
                props.onDataChange(props.data.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...props.data[rowIndex]!,
                            [columnId]: value,
                        }
                    }
                    return row
                })
                )
            },
        },
    });

    const handleURLParams = () => {
        if (props.enableURLParams) {
            const paramTableState = parseTableSearchParams();
            // console.log('tablestate', paramTableState);
            // console.log(paramTableState.filterState, props.filterState)

            if (props.filterState) {
                console.log(props.filterState);
                let found = false;
                for (let i = 0; i < props.filterState.length; i++) {
                    const state = props.filterState[i];
                    if (!paramTableState.filterState.find(($state) => $state.id === state.id)) {
                        paramTableState.filterState.push(state);
                    }
                }
                // for (const i in paramTableState.filterState) {
                //     const state = paramTableState.filterState[i];
                //     if (state.id === props.filterState.id) {
                //         found = true;
                //         break;
                //     }
                // }

                // if (!found) {
                //     paramTableState.filterState.push(props.filterState);
                // }
            }

            // Object.keys(props.filterState).forEach((key) => {
            //     console.log(key, props.filterState[key])
            // })
            setColumnFilters(paramTableState.filterState);
            // table.setPageIndex(paramTableState.pageIndex);
            // table.setPageSize(paramTableState.pageSize);
            setPagination({ pageIndex: paramTableState.pageIndex, pageSize: paramTableState.pageSize });
            setSorting(paramTableState.sorting)
        } else if (props.filterState) {
            console.log(props.filterState)
            setColumnFilters([...props.filterState])
        }
    }

    const tableResizeHandler = () => {
        const table = document.getElementById(`${id}-table-wrapper-2`);
        const filler = document.getElementById(`${id}-pagination-filler`);
        const pagination = document.getElementById(`${id}-pagination-pagination`);
        const select = document.getElementById(`${id}-pagination-select`);
        if (table?.clientWidth <= 600 && pagination?.classList?.contains("pagination-section")) {
            filler.classList.toggle("pagination-section");
            filler.style.display = "none";
            pagination.classList.toggle("pagination-section");
            select.classList.toggle("pagination-section");
        } else if (table?.clientWidth > 600 && !pagination?.classList?.contains("pagination-section")) {
            filler.classList.toggle("pagination-section");
            filler.style.display = "flex";
            pagination.classList.toggle("pagination-section");
            select.classList.toggle("pagination-section");
        }
    }

    const observerRef = useRef<IntersectionObserver>(null);

    React.useEffect(() => {
        handleURLParams();
        if (!props.enableURLParams) {
            setPagination({ pageIndex: 0, pageSize: 50 });
        }
        if (enablePagination) {
            window.addEventListener("resize", tableResizeHandler);
            tableResizeHandler();
        }
        if (props.enableStickyTitle || props.enableRowSelectionColumn) {
            const tableTitle = document.getElementById(`${id}-table-title-container-2`);
            observerRef.current = new IntersectionObserver(
                ([e]) => e.target.classList.toggle("pinned", e.intersectionRatio < 1),
                { threshold: [1] }
            )
            observerRef.current.observe(tableTitle)
        }
        return () => {
            if (props.enablePagination) {
                window.removeEventListener("resize", tableResizeHandler);
            }
            if (props.enableStickyTitle || props.enableRowSelectionColumn) {
                observerRef.current.disconnect()
            }
        }
    }, [])

    const handleSortParams = props.enableURLParams ? (id: string, dir: "asc" | "desc" | false) => {
        const url = new URL(window.location.href);
        if (dir !== false) {
            url.searchParams.set("sortID", id);
            url.searchParams.set("sortDir", dir);
        } else {
            url.searchParams.delete("sortID");
            url.searchParams.delete("sortDir");
        }
        window.history.pushState({}, "", url);
    } : (() => { return })

    React.useEffect(() => {
        // console.log(pagination);
        if (props.enableURLParams) {
            const url = new URL(window.location.href);
            url.searchParams.set("page", (pagination.pageIndex ?? 0).toString());
            url.searchParams.set("pageSize", (pagination.pageSize ?? 50).toString());
            window.history.pushState({}, "", url);
        }
    }, [pagination])

    const handlePageSizeParam = props.enableURLParams ? (size: number) => {
        const url = new URL(window.location.href);
        url.searchParams.set("pageSize", size.toString());
        window.history.pushState({}, "", url);
    } : (() => { return });

    useEffect(() => {
        if (props.enableDnD === true) {
            setPreviousPageSize(table.getState().pagination.pageSize);
            table.setPageSize(50);
            table.setColumnFilters([]);
            table.setSorting([]);
        } else if (previousPageSize) {
            table.setPageSize(previousPageSize);
            handlePageSizeParam(previousPageSize);
            handleURLParams();
        }
    }, [props.enableDnD])


    const divProps = Object.assign({}, props);
    delete divProps.data;
    delete divProps.columns;
    delete divProps.active;
    delete divProps.getRowCanExpand;
    delete divProps.renderSubComponent;
    delete divProps.datalist;
    delete divProps.enablePagination;
    delete divProps.customFilterJSX;
    delete divProps.customFilterColumnOverride;
    delete divProps.exportOptions;
    delete divProps.dndCallBack;
    delete divProps.enableDnD;
    delete divProps.enableURLParams;
    delete divProps.sortState;
    delete divProps.filterState;
    delete divProps.onPaginationChange;
    delete divProps.pagination;
    delete divProps.onTableRowChange;
    delete divProps.rowSelection;
    delete divProps.actions;
    delete divProps.tableTitle;
    delete divProps.minWidth;
    delete divProps.loadState;
    delete divProps.onDataChange;
    delete divProps.expectedRows;
    delete divProps.enableRowSelectionColumn;
    delete divProps.enableStickyTitle;
    delete divProps.dateRange;
    delete divProps.dense;
    delete divProps.onSelectionChange;
    delete divProps.getSubRow;

    useEffect(() => {
        if (props.onTableRowChange) {
            props.onTableRowChange(table.getRowModel().rows);
        }
        // Log("change")
    }, [table.getRowModel()])

    const [filterPopUpState, setFilterPopUpState] = useState<{ column: Column<any>, filterFn: FilterFnOption<any> }>({ column: undefined, filterFn: undefined });
    const [tableActionState, setTableActionState] = useState<{ cell: any, id: string }>({ cell: undefined, id: undefined });

    const id = React.useId();

    const [dateRangeDisplayState, setDateRangeDisplayState] = useState<boolean>(false);
    const isFullScreen = getWidth()();

    useEffect(() => {
        Log(columnFilters)
    }, [columnFilters])

    return (
        <div
            className={classCombine("table-wrapper-3", themeContext.value)}
            id={"table-wrapper-3"}
            style={{
                minWidth: props.minWidth,
                padding: props.padding
            }}
        >
            <div className={classCombine("table-wrapper-2", themeContext.value)} id={`${id}-table-wrapper-2`}>
                {(filterPopUpState.column?.getCanFilter()) ? (
                    <div>
                        {props.customFilterJSX?.[filterPopUpState.column.id]
                            ? React.cloneElement(props.customFilterJSX?.[filterPopUpState.column.id], {
                                column: props.customFilterColumnOverride?.[filterPopUpState.column.id] ? table.getColumn(props.customFilterColumnOverride[filterPopUpState.column.id]) : filterPopUpState.column, table: table, enableList: props.datalist,
                                popUpState: filterPopUpState.column !== undefined, onPopUpClose: (state) => { setFilterPopUpState({ column: undefined, filterFn: undefined }) }, enableURLParams: props.enableURLParams,
                                tableWrapperId: `${id}-table-wrapper-2`
                            })
                            // : <Filter column={ header.column } table={ table } enableList={ props.datalist }/>
                            : filterPopUpState.filterFn === "filterDateRange"
                                ? <PopUpFilterDate
                                    table={table}
                                    column={filterPopUpState.column}
                                    popUpState={filterPopUpState.column !== undefined}
                                    onPopUpClose={(state) => { setFilterPopUpState({ column: undefined, filterFn: undefined }) }}
                                    enableURLParams={props.enableURLParams}
                                    tableWrapperId={`${id}-table-wrapper-2`}
                                />
                                : filterPopUpState.filterFn === "filterNumberRange"
                                    ? <PopUpFilterNumberRange
                                        table={table}
                                        column={filterPopUpState.column}
                                        popUpState={filterPopUpState.column !== undefined}
                                        onPopUpClose={(state) => { setFilterPopUpState({ column: undefined, filterFn: undefined }) }}
                                        enableURLParams={props.enableURLParams}
                                        tableWrapperId={`${id}-table-wrapper-2`}
                                    />
                                    : <PopUpFilter
                                        table={table}
                                        column={filterPopUpState.column}
                                        popUpState={filterPopUpState.column !== undefined}
                                        onPopUpClose={(state) => { setFilterPopUpState({ column: undefined, filterFn: undefined }) }}
                                        enableURLParams={props.enableURLParams}
                                        tableWrapperId={`${id}-table-wrapper-2`}
                                    />

                        }
                    </div>
                ) : null}


                {props.dateRange && !isFullScreen && <PopUp
                    popUpState={dateRangeDisplayState && !isFullScreen}
                    onPopUpClose={(value) => {
                        setDateRangeDisplayState(value);
                    }}
                    title={"Date Range"}
                    style={{
                        overflow: "visible"
                    }}
                    contentStyle={{
                        overflow: "visible"
                    }}
                >
                    <DateRangeFetch
                        {
                        ...props.dateRange
                        }
                        grid
                        presets={props.dateRange.presets === undefined ? true : props.dateRange.presets}
                    />
                </PopUp>}
                {
                    tableActionState.cell && props.actions &&
                    <TableActionPopUp
                        tableBaseId={id}
                        tableWrapperId={`${id}-table-wrapper-2`}
                        table={table}
                        column={tableActionState.cell.column}
                        id={tableActionState.id}
                        popUpState={tableActionState.cell !== undefined}
                        onPopUpClose={(state) => { setTableActionState({ cell: undefined, id: undefined }) }}
                        enableURLParams={props.enableURLParams}
                        actions={props.actions}
                    />
                }

                <div
                    id={`${id}-table-title-container-2`}
                    className={classCombine((props.enableStickyTitle || props.enableRowSelectionColumn) ? "table-title-container-2" : "", themeContext.value)}
                >
                    {/* <TableTitleShadow id={id} /> */}
                    {(props.tableTitle || props.actions?.table) && <div
                        className={classCombine("table-title-container", props.dense ? "dense" : "")}
                    >
                        {/* <div
                            style={{
                                display: "flex",
                                gap: "1.5rem",
                                alignItems: "center",
                                flex: 1,
                                flexWrap: "wrap"
                            }}
                        > */}
                        {
                            props.dateRange
                                ? <TableTitleDateRange
                                    dateRange={props.dateRange}
                                    displayState={dateRangeDisplayState && isFullScreen}
                                    onDisplayStateChange={(value) => {
                                        setDateRangeDisplayState(value)
                                    }}
                                >
                                    {
                                        // typeof props.tableTitle === "string"
                                        //     ? <TableTitle>{props.tableTitle}</TableTitle>
                                        //     : props.tableTitle
                                        props.tableTitle
                                    }
                                </TableTitleDateRange>
                                : typeof props.tableTitle === "string"
                                    ? <TableTitle dense={props.dense}>{props.tableTitle}</TableTitle>
                                    : props.tableTitle
                        }
                        {

                        }
                        {/* <div
                        className={"table-date-range"}
                    >
                        <LuCalendarRange className={"table-date-range-icon"}/>

                    </div> */}
                        {/* </div> */}
                        {props.actions?.table && <div>
                            <Button
                                buttonType={"primary"}
                                icon={<IoOptions size={16} />}
                                onClick={() => {
                                    setTableActionState({ cell: {}, id: `${id}-table-btn-actions` })
                                    // setTableActionState({ cell: {}, id: `tablebtn-actions` })
                                }}
                                // id={`tablebtn-actions`}
                                id={`${id}-table-btn-actions`}
                                padding={0}
                            >
                                Actions
                            </Button>
                        </div>}
                    </div>}
                    {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) &&
                        <div
                            className={classCombine("row-select-display", props.dense ? "dense" : "")}
                        >
                            <Text
                                type="sm"
                                padding={"0 0 0.5rem 0"}
                            >

                                {`${table.getSelectedRowModel().rows.length} row(s) selected.`}
                            </Text>
                        </div>}
                </div>


                <div id={`${id}-table-wrapper`} className={classCombine("table-wrapper", themeContext.value)} ref={tableRef}>
                    <table
                        {...divProps}
                        className={classCombine("table", themeContext.value, props.className)}
                        style={{
                            ...props.style,
                            background: DivUtils.genBackground(props.background),
                            width: DivUtils.genWidth(props.width),
                            color: DivUtils.genColor(props.color)
                        }}
                        id={`${id}-table`}
                    >
                        <thead
                            id={`${id}-thead`}
                            className={themeContext.value}
                        >
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {props.enableDnD && <th key={"drag-and-drop"} id={"drag-and-drop"} className={classCombine(themeContext.value, props.dense ? "dense" : "")}>
                                    </th>}
                                    {headerGroup.headers.map(header => {
                                        // console.log(header, headerGroup);
                                        const filterFn = header.column.columnDef.filterFn;
                                        return (
                                            <th
                                                key={header.id}
                                                id={header.id}
                                                colSpan={header.colSpan}
                                                className={classCombine(themeContext.value, (header.column.id === "table-actions" || header.column.id === "table-select") ? "pinned" : "", props.dense ? "dense" : "")}
                                                onClick={() => {
                                                    if (header.column.columns.length > 0) {
                                                        const visibility = header.column.columns.reduce((acc, subColumn, i) => {
                                                            if (i === 0) {
                                                                return acc
                                                            };
                                                            acc[subColumn.id] = columnVisibility[subColumn.id] === undefined ? false : !columnVisibility[subColumn.id];
                                                            return acc;
                                                        }, { [header.id]: true });
                                                        setColumnVisibility({
                                                            ...columnVisibility,
                                                            ...visibility
                                                        })
                                                    }
                                                }}
                                                style={
                                                    header.column.id === "table-actions"
                                                        ? { ...getCommonPinningStylesRight(header.column) }
                                                        : header.column.id === "table-select"
                                                            ? { ...getCommonPinningStylesLeft(header.column) }
                                                            : {}
                                                }
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            {... {
                                                                className: `${themeContext.value} table-header-text ${header.column.getCanSort() ? "can-sort" : ""}`,
                                                            }}
                                                            style={{
                                                                cursor: header.column.columns?.length > 0 ? "pointer" : "default"
                                                            }}
                                                        >

                                                            {
                                                                isColumnGroupHidden(header.column, columnVisibility)
                                                                    ? <div>...</div>
                                                                    : flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext(),
                                                                    )
                                                            }

                                                            {
                                                                header.column.columns?.length > 0 && header.subHeaders?.length > 0 &&
                                                                <div>
                                                                    {
                                                                        header.subHeaders?.length !== header.column.columns?.length
                                                                            ? <TbLayoutSidebarLeftExpand className="expander active" size={16} />
                                                                            : <TbLayoutSidebarRightExpand className="expander" size={16} />
                                                                    }
                                                                </div>
                                                            }

                                                            <div className={"table-filter-sort-container"} id={`${header.column.id}-filter-sort`}>
                                                                {
                                                                    (header.column.getCanSort())
                                                                        ? header.column.getIsSorted() === "asc"
                                                                            ? <FaSortUp className={"table-sort active"} size={16} onClick={(e) => {
                                                                                header.column.getToggleSortingHandler()(e);
                                                                                handleSortParams(header.column.id, false);
                                                                                setSorting([]);
                                                                            }} />
                                                                            : header.column.getIsSorted() === "desc"
                                                                                ? <FaSortDown className={"table-sort active"} size={16} onClick={(e) => {
                                                                                    // header.column.getToggleSortingHandler()(e);
                                                                                    setSorting([{ id: header.column.id, desc: false }]);
                                                                                    handleSortParams(header.column.id, "asc");
                                                                                }} />
                                                                                : <FaSort className={"table-sort"} size={16} onClick={(e) => {
                                                                                    // header.column.getToggleSortingHandler()(e);
                                                                                    setSorting([{ id: header.column.id, desc: true }]);
                                                                                    handleSortParams(header.column.id, "desc");
                                                                                }} />
                                                                        : null
                                                                }
                                                                {/* { header.column.getCanFilter() && <FaFilter className={["table-filter-icon", header.column.getFilterValue() ? "active" : undefined, themeContext.value].join(" ").trim()} size={16} onClick={()=>{setToggleFilter(!toggleFilter)}}/>} */}
                                                                {header.column.getCanFilter() &&
                                                                    <FaFilter
                                                                        className={["table-filter-icon", header.column.getFilterValue() ? "active" : undefined, themeContext.value].join(" ").trim()}
                                                                        size={16}
                                                                        // onClick={()=>{setColumnFilterColumnState({...columnFilterToggleState, [header.column.id]: !columnFilterToggleState[header.column.id]})}}
                                                                        onClick={() => {
                                                                            setFilterPopUpState({
                                                                                column: header.column,
                                                                                filterFn: filterFn
                                                                            })
                                                                        }}
                                                                    />}
                                                            </div>


                                                        </div>
                                                    </>
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody className={themeContext.value}>
                            {

                                // (tableData.length > 0 || props.loadState)
                                // ?   
                                table
                                    .getRowModel()
                                    .rows.slice((pagination.pageIndex) * pagination.pageSize, (pagination.pageIndex + 1) * pagination.pageSize)
                                    .map((row, i) => {
                                        const indexOffset = (pagination.pageIndex) * pagination.pageSize;
                                        const index = i + indexOffset;
                                        // console.log('row', row);
                                        return (
                                            <Fragment key={row.id}>
                                                {
                                                    props.enableDnD ?
                                                        <DraggableRow key={row.id} row={row} reorderRow={props?.dndCallBack} active={props.active} theme={themeContext.value} />
                                                        :
                                                        <tr
                                                            id={`table-data-${index}`}
                                                            key={row.id}
                                                            className={classCombine("table-data", themeContext.value, props.active ? "active" : "", props.rowSelection?.selectedRowIndexState === i ? "selected" : "")}
                                                            onClick={() => {
                                                                if (props.rowSelection?.onSelectedRowIndexChange) props.rowSelection.onSelectedRowIndexChange(i, row);
                                                            }}
                                                        >
                                                            {row.getVisibleCells().map(cell => {
                                                                return (
                                                                    <td
                                                                        key={cell.id}
                                                                        id={`${cell.column.id}-${index}`}
                                                                        className={classCombine("v2", themeContext.value, (cell.column.id === "table-actions" || cell.column.id === "table-select") ? "pinned" : "", props.dense ? "dense" : "")}
                                                                        style={
                                                                            cell.column.id === "table-actions"
                                                                                ? { ...getCommonPinningStylesRight(cell.column) }
                                                                                : cell.column.id === "table-select"
                                                                                    ? { ...getCommonPinningStylesLeft(cell.column) }
                                                                                    : {}
                                                                        }
                                                                    >
                                                                        {
                                                                            isColumnGroupHidden(cell.column, columnVisibility)
                                                                                ? <div>...</div>
                                                                                : cell.column.id === "table-actions" && props.actions?.row
                                                                                    ? React.cloneElement(<div></div>,
                                                                                        {
                                                                                            children:
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        justifyContent: "left",
                                                                                                        alignItems: "center",
                                                                                                        cursor: "pointer"
                                                                                                    }}
                                                                                                    key={`${cell.column.id}-${index}`}
                                                                                                >
                                                                                                    <BsThreeDotsVertical
                                                                                                        size={16}
                                                                                                        onClick={() => {
                                                                                                            setTableActionState({ cell: cell, id: `${cell.column.id}-${index}` })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                        }
                                                                                    )
                                                                                    : flexRender(
                                                                                        cell.column.columnDef.cell,
                                                                                        cell.getContext()
                                                                                    )
                                                                        }
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                }
                                                {
                                                    props.renderSubComponent ? props.renderSubComponent({ row: row, id: id }) : null
                                                }
                                            </Fragment>
                                        );
                                    })
                                // :   <tr
                                //     >
                                //         <td
                                //             className={ "v2" } 
                                //             colSpan={ table.getAllColumns().length}
                                //             style={{
                                //                 // display: "flex",
                                //                 // justifyContent: "center",
                                //                 // alignItems: "center",
                                //                 fontSize: "1rem",
                                //                 textAlign: "center",
                                //             }}
                                //         >
                                //             <div>
                                //                 No data found.
                                //             </div>
                                //         </td>
                                //     </tr>

                            }
                        </tbody>
                    </table>
                </div>
                {
                    enablePagination &&
                    <div
                        className={"pagination-container"}
                    >
                        {

                            <div
                                className={classCombine("pagination-section", "filler")}
                                id={`${id}-pagination-filler`}
                            >

                            </div>
                        }
                        <div
                            className={classCombine("pagination", "pagination-section")}
                            id={`${id}-pagination-pagination`}
                        >
                            <Button
                                variant={"transparent"}
                                className={"pagination-btn"}
                                padding={0}
                                onClick={() => {
                                    scrollToTop();
                                    table.setPageIndex(0);
                                }}
                            >
                                {/* {"<<"} */}
                                <HiMiniChevronDoubleLeft size={16} />
                            </Button>
                            <Button
                                variant={"transparent"}
                                className={"pagination-btn"}
                                padding={0}
                                onClick={() => {
                                    scrollToTop();
                                    table.previousPage();
                                }}
                            >
                                {/* {"<"} */}
                                <HiMiniChevronLeft size={16} />
                            </Button>
                            <div>

                                <Input
                                    value={(pagination.pageIndex ?? 0) + 1}
                                    onChange={e => {
                                        const isNumber = /^\d+$/g;
                                        if (e.currentTarget.value.match(isNumber)) {

                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            table.setPageIndex(page);
                                        }
                                        // document.getElementById(`${id}-pagination-page`).style.width = `calc(${e.currentTarget.value?.length}ch + 1rem)`;
                                    }}
                                    id={`${id}-pagination-page`}
                                    className={"pagination-page"}
                                    width={`calc(${(pagination?.pageIndex?.toString() ?? "1")?.length}ch + 2rem)`}
                                    style={{
                                        textAlign: "center"
                                    }}
                                />
                            </div>
                            <Button
                                variant={"transparent"}
                                className={"pagination-btn"}
                                padding={0}
                                onClick={() => {
                                    // shouldn't need to add this, but for some reason .nextPage() is not checking pages properly
                                    if (table.getCanNextPage()) {
                                        table.nextPage();
                                        scrollToTop();
                                    }
                                }}
                            >
                                {/* {">"} */}
                                <HiMiniChevronRight size={16} />
                            </Button>
                            <Button
                                variant={"transparent"}
                                className={"pagination-btn"}
                                padding={0}
                                onClick={() => {
                                    scrollToTop();
                                    table.setPageIndex(table.getPageCount() - 1);
                                }}
                            >
                                {/* {">>"} */}
                                <HiMiniChevronDoubleRight size={16} />
                            </Button>
                        </div>
                        <div
                            className={classCombine("row-select", "pagination-section")}
                            id={`${id}-pagination-select`}
                        >
                            {
                                pagination.pageSize &&
                                <Select
                                    onChange={e => {
                                        const value = Number(e.currentTarget.value);
                                        table.setPageSize(value);
                                        handlePageSizeParam(value);
                                    }}
                                    defaultValue={pagination.pageSize}
                                    className={"pagination-select"}
                                    // width={"100px"}
                                    style={{
                                        padding: "0.5rem",
                                        paddingRight: "2rem",
                                        fontSize: "0.8rem"
                                    }}
                                >
                                    <Option value={10}>
                                        10 Rows
                                    </Option>
                                    <Option value={25}>
                                        25 Rows
                                    </Option>
                                    <Option value={50}>
                                        50 Rows
                                    </Option>
                                </Select>
                            }
                        </div>

                    </div>
                }
            </div>
        </div>
    );
};

export * from "./types";
export * from "./filters";
export * from "./actions";
export * from "./utils";