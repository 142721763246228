import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/announcement"

const useAnnouncementEndpoints = () => {
    const acknowledge = generatePostEndpoint<{id: string}, string>(baseURL + "/acknowledge")
    const check = generateGetEndpoint<Array<any>>(baseURL + "/check");
    const create = generatePostEndpoint<{announcement: any}, any>(baseURL + "/create", true);
    const deleteAnnouncement = generatePostEndpoint<{announcements: Array<string>}, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{annoucement: any}, any>(baseURL + "/edit");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, {data: Array<any>, users: Array<any>, roles: Array<any>}>(baseURL + "/fetch-all");
    const fetchRecipients = generateGetEndpoint<{users: Array<any>, roles: Array<any>}>(baseURL + "/fetch-recipients");
    const fetchUnacknowledged = generateGetEndpoint<Array<any>>(baseURL + "/fetch-unacknowledged");

    return {
        acknowledge,
        check,
        create,
        deleteAnnouncement,
        edit,
        fetchAll,
        fetchRecipients,
        fetchUnacknowledged,
    }
}

export {
    baseURL,
    useAnnouncementEndpoints
};