import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useAssignmentEndpoints } from "./assignment";
import { Types } from "src/api/@types";
import { usePDTruckConfigEndpoints } from "./config";
import { useDSWEndpoints } from "./dsw";

const baseURL = "/pd"

const usePDEndpoints = () => {
    const assignment = useAssignmentEndpoints();
    const config = usePDTruckConfigEndpoints();
    const createTruck = generatePostEndpoint<{Truck: any}, string>(baseURL + "/create-truck");
    const createTruckMany = generatePostEndpoint<{trucks: Array<any>}, string>(baseURL + "/create-truck-many");
    const parseSheet = generatePostEndpoint<FormData, Array<any>>(baseURL + "/parse-sheet");
    const deleteTruck = generatePostEndpoint<{truckId: string}, string>(baseURL + "/delete-truck");
    const dsw = useDSWEndpoints();
    const editTruck = generatePostEndpoint<{Truck: any}, any>(baseURL + "/edit-truck");
    const fetchDrivers = generateGetEndpoint<Array<any>>(baseURL + "/fetch-drivers");
    const fetchTires = generatePostEndpoint<{truckId: string}, [Array<any>, any, Array<string>]>(baseURL + "/fetch-tires");
    const fetchTruck = generatePostEndpoint<{id?: string, truck_number?: string}, any>(baseURL + "/fetch-truck");
    const fetchTrucks = generateGetEndpoint<Array<any>>(baseURL + "/fetch-trucks"); 
    const fetchTrucksSimple = generateGetEndpoint<Types.PD.FetchTrucksSimple>(baseURL + "/fetch-trucks-simple"); 
    const fetchWorkOrders = generatePostEndpoint<{truckNumber: string | number}, Array<any>>(baseURL + "/fetch-work-orders");
    
    return {
        assignment,
        config,
        createTruck,
        createTruckMany,
        deleteTruck,
        dsw,
        editTruck,
        fetchDrivers,
        fetchTires,
        fetchTruck,
        fetchTrucks,
        fetchTrucksSimple,
        fetchWorkOrders,
        parseSheet,
    }
}

export {
    baseURL,
    usePDEndpoints
};