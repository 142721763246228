import { useEvent } from "src/@fair";

export const Log = ( ...args: any[]) => {
    if ( process.env["REACT_APP_DEBUG"] === "true" ) console.log( ...args );
};

export const Trace = ( ...args: any[] ) => {
    if ( process.env["REACT_APP_DEBUG"] === "true" ) console.trace( ...args );
}

export const ErrorLog = (error, event?, children?, expiresIn?) => {

    let errorMessage = error.message;

    if (error?.response?.status === 403 && error?.response?.data?.error !== "Session expired.") errorMessage = "You lack the required permissions to perform this action."
    if (error?.response?.status === 403 && error?.response?.data?.error === "Session expired.") errorMessage = "24 hour session expired. Please log back in."
    if (error.code === "ERR_CANCELED") return;

    console.log( error );
    
    // This is (probably) improper.
    // Should call useEvent() hook within this function, rather than passing it as an argument.
    // However, using useEvent() within this function would require some changes to the way this works to avoid "invalid hook call" errors...
    if (event) {
        event(
            {
                action: "add",
                options: {
                    expiresIn: expiresIn ?? 5000,
                    animate: true,
                    children: children ?? <span><b>Error:</b> { errorMessage} </span>,
                    type: "error"
                }
            }
        )
    }
}

export const useErrorLog = () => {
    const event = useEvent();
    
    const errorLog = (error, children?, expiresIn?) => {
        ErrorLog(error, event, children, expiresIn);
    }

    return errorLog
}