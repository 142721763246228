import { Types } from "src/api";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/organization"

const useOrganizationEndpoints = () => {
    const active = generatePostEndpoint<{id: string}, any>(baseURL + "/active");
    const checkCredentials = generateGetEndpoint<{has_credentials: boolean}>(baseURL + "/check-credentials");
    const checkCredentialsGlobal = generatePostEndpoint<{id: string}, {has_credentials: boolean}>(baseURL + "/check-credentials/global");
    const checkImport = generateGetEndpoint<{organizations: Array<any>}>(baseURL + "/check-import")
    const createCredentials = generatePostEndpoint<Partial<{username: string, password: string}>, any>(baseURL + "/create-credentials");
    const createCredentialsGlobal = generatePostEndpoint<Partial<{id: string, username: string, password: string}>, any>(baseURL + "/create-credentials/global");
    const createSignUp = generatePostEndpoint<FormData, any>(baseURL + "/create-sign-up");
    const create = generatePostEndpoint<FormData, any>(baseURL + "/create");
    const deleteCredentials = generatePostEndpoint<{}, string>(baseURL + "/delete-credentials");
    const deleteCredentialsGlobal = generatePostEndpoint<{id: string}, string>(baseURL + "/delete-credentials/global");
    const edit = generatePostEndpoint<FormData, any>(baseURL + "/edit");
    const editGlobal = generatePostEndpoint<FormData, any>(baseURL + "/edit-global");
    const fetch = generateGetEndpoint<any>(baseURL + "/fetch");
    const fetchGlobal = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch-global");
    const importUser = generatePostEndpoint<{source: string, destination?: string, accountEmail: string}, any>(baseURL + "/import-user");
    const transferOwnership = generatePostEndpoint<{accountEmail: string, roleId: string}, any>(baseURL + "/transfer-ownership");
    const uploadLink = generatePostEndpoint<FormData, string>(baseURL + "/upload-link");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        active,
        checkCredentials,
        checkCredentialsGlobal,
        checkImport,
        create,
        createCredentials,
        createCredentialsGlobal,
        createSignUp,
        deleteCredentials,
        deleteCredentialsGlobal,
        edit,
        editGlobal,
        fetch,
        fetchGlobal,
        importUser,
        transferOwnership,
        uploadLink,
        view
    }
}

export {
    baseURL,
    useOrganizationEndpoints,
};