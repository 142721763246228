import { CSSProperties, useContext } from "react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { ThemeContext } from "src/@fair";
import "./index.scss";
import { classCombine } from "@fairview-group/utils";

interface ThumbsProps {
    size?: number;
    approvalState?: boolean;
    className?: string
}

export const ThumbsUp = ({ size, approvalState, className }: ThumbsProps) => {
    const themeContext = useContext(ThemeContext);
    return (
        <FaRegThumbsUp
            size={size}
            className={classCombine("check", themeContext.value, "up", approvalState ? "active" : "none", className)}
        />
    )
};


export const ThumbsDown = ({ size, approvalState, className }: ThumbsProps) => {
    const themeContext = useContext(ThemeContext);
    return (
        <FaRegThumbsDown
            size={size}
            className={classCombine("check", themeContext.value, "down", approvalState ? "active" : "none", className)}
        />
    )
};