import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/form" + "/template"

const useFormTemplateEndpoints = () => {
    const create = generatePostEndpoint<{template: Partial<Types.Form.Template.TemplateRequest>}, string>(baseURL + "/create");
    const deleteTemplate = generatePostEndpoint<{id: string}, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{template: any}, Partial<Types.Form.Template.TemplateResponseBase>>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch");
    const fetchActive = generateGetEndpoint<Types.Form.Template.TemplateFetchAll>(baseURL + "/fetch-active");
    const view = generateGetEndpoint<Array<Types.Form.Template.TemplateResponseBase>>(baseURL + "/view");

    return {
        create,
        deleteTemplate,
        edit,
        fetch,
        fetchActive,
        view,
    }
}

export {
    baseURL,
    useFormTemplateEndpoints
};