import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/schedule" + "/group" 

const useScheduleGroupEndpoints = () => {
    const create = generatePostEndpoint<{ scheduleGroup: any }, string>(baseURL + "/create");
    const deleteGroup = generatePostEndpoint<{scheduleGroups: Array<string>}, {message: string, deleted: boolean, type: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ scheduleGroup: any }, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch");
    const fetchAll = generateGetEndpoint<Array<any>>(baseURL + "/fetch-all");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");
    

    return {
        create,
        deleteGroup,
        edit,
        fetch,
        fetchAll,
        view
    }
}

export {
    baseURL,
    useScheduleGroupEndpoints
};